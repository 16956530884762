import { Link } from "gatsby";
import React from "react";

export default function AreaTemplate({ data, pageContext, location }) {
  const { cityFullName, citySlug, cityDisplayName, area, areaSlug, listings } =
    pageContext;

  return (
    <>
      <section className="block">
        <ul className="breadcrumb">
          <li>
            <Link to="/">&#8962; Home</Link>
          </li>
          <li>
            <Link to={`/${citySlug}/`}>{cityDisplayName}</Link>
          </li>
        </ul>
      </section>
      <section className="block">
        <ul className="big-link-list">
          <li>{area}</li>
        </ul>
      </section>
      <section className="block">
        <ul className="link-list listing-list">
          {listings.map(
            (listing, i) =>
              !listing.data.Skip &&
              listing.data.Publish && (
                <li key={i}>
                  <Link to={`/${citySlug}/${listing.data.CID}/`}>
                    <span className="primary">
                      {listing.data.Name}
                      {listing.data.Must && <span className="must">●</span>}
                    </span>
                    <span className="secondary">
                      {listing.data.Korean_Name}
                    </span>
                  </Link>
                </li>
              )
          )}
        </ul>
      </section>
    </>
  );
}
